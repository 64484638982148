<template >
  <div class="about">
    <!-- <div class="header">
      <Parallax
        :speed="-.25"
        parallaxType="Offset"
        style="position:relative; top:0; right:0; display:block; display:block;"
        :marged="false"
        :masked="true"
        :skewed="false"
        :autosize="true"
        :rotate="true"
        :degree="0"
        :minMax="true"
        class="ratio ratio_16_9"
      >
        <v-img class :src="headerAteliers" :contain="false" />
      </Parallax>
    </div>-->
    <div
      class="home_video soutien"
      :style="'background-image:url(); background-position:center center; background-size:cover; padding:20px;'"
    >
      <div class="centered">
        <div class="ratio ratio_16_9">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/Aztq5d7RuGY?rel=0&autoplay=0&loop=1&showinfo=0&color=white&iv_load_policy=3"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <v-container :style="'padding-bottom:'+((isMobile)?0:321)+'px;'">
      <div class="article_content">
        <h1 class="styloText">La Bige ?</h1>
        <br />
        <p class="styloText first_paragraph">
          <a
            href="https://fr.wikipedia.org/wiki/Bige#:~:text=Un%20bige%20est%20un%20char,du%20IV%20e%20si%C3%A8cle%20av."
            target="_blank"
            title="bige  wikipedia"
          >À l'instar du char antique</a>
          , Bige est une alliance entre méthodologies créatives et technologies issues du numérique.
          <br />
          <b>
            <span class="surligneur">Tout débute par la croyance dans ton idée.</span>
          </b>
          L’unique objectif de Bige est d’accompagner la mise en forme de nouveaux produits
          tout en facilitant la mise en oeuvre des outils numériques nécessaires à éprouver les concepts.
        </p>
        <br />
        <Onscreen :moveY="0">
          <h2 pa-10 class="styloText">:: Méthodologie</h2>
        </Onscreen>
        <v-layout row>
          <v-flex md6 pa-10>
            <Onscreen :moveY="0">
              <div>
                <h2 class="styloText">
                  <span class="num">1.</span>Conseils & Workshop.
                </h2>
                <p class="styloText">
                  Apporter un regard extérieur et partager nos visions du design et du développement dans l’objectif
                  de conforter des choix technologiques et d’expérience utilisateur.
                  <span
                    class="surligneur"
                  >J'aime vulgariser</span>&nbsp;
                  <a
                    href="/feeds/le-petit-lexique-de-ton-ux-designer"
                    title="lexique de ton UX"
                    target="_blank"
                  >les champs lexicaux de tes experts</a> pour les rendre accéssibles au plus grand nombre.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:121)" :delay="500">
              <div>
                <h2 class="styloText">
                  <span class="num">2.</span>Conception et Prototypage.
                </h2>
                <p class="styloText">
                  <b class="surligneur">Ensemble</b>,
                  nous pratiquerons différentes itérations avec la conception et l’écriture de prototypes interactifs destinés
                  à éprouver les produits et à les optimiser pour minimiser les impacts et définir les fonctionnalités minimales
                  avant leur mise en oeuvre.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:0)">
              <div>
                <h2 class="styloText">
                  <span class="num">3.</span>Nous partagerons nos visions du Design.
                </h2>
                <p class="styloText">
                  Nous partagerons différentes approches graphiques pour définir la
                  <span
                    class="surligneur"
                  >singularité du design produit</span> et en faire une conviction depuis la création du Logotype jusqu’à l’écriture de son
                  <a
                    href="/feeds/design-system-bige-un-premier-article-sur-les-glyphs-"
                    title="design system bige"
                    target="_blank"
                  >Design Systèmes.</a>
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:121)" :delay="500">
              <div>
                <h2 class="styloText">
                  <span class="num">4.</span>Nous concevrons un premier MVP
                </h2>
                <p class="styloText">
                  Nous synthétiserons et définirons les fonctionnalités minimales viables du produit pour concevoir le plus rapidement possible une version destinée à confronter les utilisateurs à l’usage.
                  <br />
                  <b>
                    <span
                      class="surligneur"
                    >« Si tu es fier de ton produit lorsque tu le sors c’est qu’il est trop tard. »</span>
                  </b>
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:0)">
              <div>
                <h2 class="styloText">
                  <span class="num">5.</span>Nous étudirons et réitérerons le MVP
                </h2>
                <p class="styloText">
                  Après analyse des usages nous définirons les actions à entreprendre,
                  réitérerons dans le cas ou le MVP ne répondrait pas à l'usage puis entamerons
                  la mise en oeuvre du produit fini lorsque les tests seront concluants.
                  En cas d’échec sur le produit, nous écrirons un POST Mortem pour garder une trace
                  et nous remémorer ce qui n’a pas fonctionné.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:121)" :delay="500">
              <div>
                <h2 class="styloText">
                  <span class="num">6.</span>Nous Designerons tes API.
                </h2>
                <p class="styloText">
                  Nos designs et développements d'interfaces de programmation répondent aux standards open API sous RESTFULL et GRAPHQL
                  pour chaque projet dans le but d’anticiper et d’apporter toujours la meilleure solution aux usages.
                  <b>
                    <span class="surligneur">Nous documentons</span>
                  </b> pour assurer la transmission de savoir du produit.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:0)">
              <div>
                <h2 class="styloText">
                  <span class="num">7.</span>Nous développerons le produit.
                </h2>
                <p class="styloText">
                  Depuis le succès du MVP (minimum viable product), nous développerons l’ensemble des fonctionnalités
                  et finaliserons le produit répondant à 100% des critères attendus lors du Design Thinking.
                  Un produit durable nécessite un développement continu, c'est pourquoi nous n'établirons pas de fin de projet
                  car cela signerait l'obsolescence programmée du produit .
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:156)" :delay="500">
              <div>
                <h2 class="styloText">
                  <span class="num">8.</span>Nous attacherons de l’importance aux détails.
                </h2>
                <p class="styloText">
                  Nous apportons un réel savoir faire en animation et conception UI optimisée pour le web comme pour mobile.
                  Nous plaçons les animations nécessaires et aux instants clés pour délivrer des messages mémorables aux utilisateurs aussi la gamification et son analyse n'aura plus de secrerts.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:0)">
              <div>
                <h2 class="styloText">
                  <span class="num">9.</span>Nous intégrerons les interfaces.
                </h2>
                <p class="styloText">
                  Seul ou avec ton équipe, nous assemblerons les fragments pour mettre l’accent sur l’accessibilité,
                  un bon SEO et un code élégant.
                  <br />
                  <b>
                    <span
                      class="surligneur"
                    >« Un produit mal intégré est souvent la cause de son échec. »</span>
                  </b>
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:156)" :delay="500">
              <div>
                <h2 class="styloText">
                  <span class="num">10.</span>Nous déploierons et accompagnerons.
                </h2>
                <p class="styloText">
                  C'est en s'adaptant à ton infrastructure que le produit fonctionne.
                  En connaissance de cause, la Bige s'adapte aux infrastructures tout en promouvant l'expérience docker.
                  Ceci pour simplifier la maintenance et le déploiement du produit par une équipe dédiée ou ton hébergeur.
                  Nous accompagnerons les premiers déploiements pour que la maintenance puisse prendre le relais sereinement.
                </p>
              </div>
            </Onscreen>
          </v-flex>
        </v-layout>
      </div>
    </v-container>

    <Onscreen :moveY="((isMobile)?0:-100)" :delay="500">
      <div class="slice_up postit" style="display:block; padding-top:10vh; padding-bottom:10vh; ">
        <blockquote class="styloText message">
          "La liberté commence où l'ignorance finit."
          <span class="author">Victor Hugo</span>
        </blockquote>
      </div>
    </Onscreen>

    <v-container style>
      <div class="article_content" :style="'padding-bottom:'+((isMobile)?0:101)+'px;'">
        <v-layout row>
          <!-- <v-flex md6>
            
          </v-flex>-->
          <v-flex md12 pa-10>
            <Onscreen :moveY="((isMobile)?0:0)">
              <h2 pa-10 class="styloText">:: Technologies</h2>
              <p class="styloText">
                Nous pensons que la tecnologie accompagne le produit et non l'inverse.
                C'est pourquoi nous sélectionnons les langages les plus adaptés et ne proposons pas de stack précise.
                <b>
                  <span class="surligneur">On s'adapte</span>
                </b> ceci dit voici un extrait de technologies maîtrisées et généralement recommandées
                <span
                  class="surligneur"
                >avec la Bige.</span>
                L'idée est que chaque besoin nécéssite sa propre technologie
                et chaque service doit d’être facilement remplaçable
                par le langage le plus à même à répondre au besoin.
              </p>
            </Onscreen>
          </v-flex>
          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:0)">
              <div>
                <h2 class="styloText">Web Back End</h2>
                <p class="styloText">
                  Après des années à travailler avec différents langages, je me suis orienté vers PYTHON, GO et NODEJS.
                  Tous les Frameworks ne se valent pas, nous utiliserons principalement
                  <b>
                    <span class="surligneur">LoopBack</span>
                  </b> pour l'élaboration de prototypes robustes.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:121)">
              <div>
                <h2 class="styloText">Web Front End</h2>
                <p class="styloText">
                  Aujourd’hui les interfaces web ressemblent de plus en plus aux applications mobiles.
                  <b>
                    <span class="surligneur">VUEJS</span>
                  </b>, REACT comme ANGULAR n'ont aucuns secrets pour offrir le meilleur du réactif aux futurs utilisateurs.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:0)">
              <div>
                <h2 class="styloText">Mobile et autres display tactiles</h2>
                <p class="styloText">
                  Après des années à écrire et ré-écrire des applications mobiles en environnement C, Java puis Swift et Kotlin.
                  Je me suis orienté sur
                  <b>
                    <span class="surligneur">Native Script</span>
                  </b> qui répond parfaitement au cross plateform et à la majorité des concepts
                  tout en minimisant les coûts liés à la maintenance.
                  Les applications C# sous UNITY pour l'exploitation VR et AR évoluent et laissent le champs libre à ton imagination.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:121)">
              <div>
                <h2 class="styloText">Stockage de données</h2>
                <p class="styloText">
                  Je m'éfforce à sélectionner la typologie de base de données selon le concept et son utilisation.
                  Je travaille principalement avec
                  <b>
                    <span class="surligneur">Mongo, Redis, Postgres et Cassandra</span>
                  </b> selon les volumes de données chaudes et froides ou encore la volatilité des données à traiter.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:0)">
              <div>
                <h2 class="styloText">Autentification et Utiliisateurs</h2>
                <p class="styloText">
                  J'apporte une expertise d'
                  <b>
                    <span class="surligneur">intégration de services OAUTH2</span>
                  </b> avec l'intégration de réseaux sociaux.
                  Une bonne connaissance et pratique d'écriture de scopes destinés à simplifier les post traitements et améliorer les usages en back-office.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:121)">
              <div>
                <h2 class="styloText">Classification et Réseaux de neurones</h2>
                <p class="styloText">
                  Je propose les compétences techniques recquises pour entraîner et faire évoluer vos réseaux de neurones sur des cas d'usages avancés et précis.
                  Ici nous ne parlons pas d'intelligence artificielle et il n'y a pas de hold-up simplement une réelle utilité et un gain de temps et d'énergie sur tes traitements lourds.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:0)">
              <div>
                <h2 class="styloText">Commerce et modes de paiement</h2>
                <p class="styloText">
                  J'adapte mes connaissances en processus d'encaissement et gestion de transactions monétaires que ce soit pour un simple checkout ou
                  <span
                    class="surligneur"
                  >des abonnements premiums et freemium.</span>
                  Je sais implémenter tout mode de transactions qu'ils soient par carte bancaires, Apple Pay, Google Checkout ou autres unitées dématérialisées et sans banques.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:121)">
              <div>
                <h2 class="styloText">Comme un Lego</h2>
                <p class="styloText">
                  <span
                    class="surligneur"
                  >Mes services sont comme des briques que l'on ajoute puis remplace.</span> Je ne conçois pas d'infrastructures des années 2000 et pense toujours à demain.
                  C'est pour cette raison que Bige ne vend pas de CMS ni de stack ou encore de quelconques services SAAS.
                  Je réponds de façon viable, durable et sans contraintes en évitant que ton infrastructure s'en retrouve pieds et poings liés.
                </p>
              </div>
            </Onscreen>
          </v-flex>
        </v-layout>
      </div>
    </v-container>
    <Onscreen :moveY="((isMobile)?0:-10)" :delay="500">
      <div
        class="action"
        style="text-align:center; display:block; padding-top:10vh; padding-bottom:10vh; "
      >
        <blockquote class>
          "Parce que j'aime ça et ne sais rien faire d'autre"
          <span class="author">François Trufaud</span>
        </blockquote>
      </div>
    </Onscreen>
  </div>
</template>
<script>
import Parallax from "@/components/UI/Parallax";
import Onscreen from "@/components/UI/OnScreen";
import * as thinkingMotion from "@/assets/motions/UI/design_thinking.json";
import * as sprintMotion from "@/assets/motions/UI/sprint.json";
import strategy from "@/assets/images/SVG/strategy.svg";
import advice from "@/assets/images/SVG/advice.svg";
import scrum from "@/assets/images/SVG/scrum.svg";
import ideation from "@/assets/images/SVG/ideation.svg";
import prototypage from "@/assets/images/SVG/prototypage.svg";
const Lottie = () => import("@/components/LottieMotion.vue");

import headerAteliers from "@/assets/images/HP/header_about.jpg";

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { Parallax, Onscreen, Lottie },
  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    },
    fixedTab: {
      get: function() {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return false;
          case "sm":
            return true;
          case "md":
            return true;
          case "lg":
            return true;
          case "xl":
            return true;
          default:
            return true;
        }
      }
    }
  },
  data() {
    return {
      headerAteliers: headerAteliers,
      thinking: {
        animationData: thinkingMotion.default,
        loop: true,
        autoplay: true
      },
      sprint: {
        animationData: sprintMotion.default,
        loop: true,
        autoplay: true
      },
      strategy: strategy,
      scrum: scrum,
      ideation: ideation,
      advice: advice,
      prototypage: prototypage
    };
  },
  methods: {
    handleAnimation: function() {}
  }
};
</script>
<style scoped>
.block_image {
  text-align: left;
}
.home_video {
  width: 100%;
  /* max-height: 40vh; */
  position: relative;
}
.home_video .centered {
  top: 0vh;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  max-width: 560px;
  display: block;
  position: relative;
}
</style>